@import "@/assets/styles/global/lib.scss";









































































































































































































.review-plan {
  .markdown {
    display: inline-block;
    .p {
      font-size: size(12);
      line-height: size(17);
      padding-top: size(10);
      margin-bottom: size(15);
      color: #000000;
    }
    em {
      font-style: italic;
      font-weight: normal;
    }
    strong {
      font-weight: bolder;
      em {
        font-weight: bolder;
      }
    }
    ul {
      list-style-type: disc;
      padding-left: size(20);
      margin-bottom: size(15);
      li {
        font-size: size(14);
        line-height: size(20);
        padding-top: size(6);
        color: #000000;
      }
    }
  }
}
