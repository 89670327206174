@import "@/assets/styles/global/lib.scss";































.error-msg {
  width: size(275);
}
.dropdown {
  position: relative;

  select {
    max-width: size(1000);
  }

  &.disable {
    color: grey;
  }

  &__list {
    &__wrap {
      position: absolute;
      background: #fff;
      left: 0;
      min-width: size(100);
      max-height: size(450);
      overflow-y: auto;
      padding-bottom: size(10);
      z-index: 99;
      transform-origin: top;
      transition: tr .4s ease-in-out;
      box-shadow: 0 size(1) size(2) 0 var(--gray-line);
      &.add-height {
        height: size(300)!important;
      }
    }
    background-color: #fff;
    padding: size(4) size(15);
    font-size: size(20);
    line-height: size(24);
    color: var(--regular-text);
    font-family: var(--regular-font);
    cursor: pointer;
    &__arrow-icon {
      &.rotate {
        transform: rotate(180deg);
      }
      transition: transform .3s ease-in-out;
    }
    &.selected {
      color: var(--on-boarding-btn-bg);
    }
    &:hover, &:focus {
      color: var(--on-boarding-btn-bg);
    }
  }
}
