@import "@/assets/styles/global/lib.scss";





































































































































































































































































































































.steps-wrapper {
  .variations-types-wrap {
    .variation {
      &.rs_paygo_enterprise_c1x {
        display: none;
      }
    }
  }
}
.choose-license {
  &.new-project {
    h2 {
      margin-bottom: size(20);
    }
  }
  .license-wrapper {
    margin-top: size(100);
    margin-bottom: size(45);
    @media screen and (min-width: $breakpoint-md) {
      display: flex;
      flex-direction: row;
    }
    .license {
      width: size(250);
      margin-right: size(18);
      background: var(--rs-white-color);
      border-radius: size(6);
      margin-bottom: size(50);
      box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
        0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
      @media screen and (min-width: $breakpoint-md) {
        margin-bottom: 0;
      }
      .image-block {
        height: size(80);
        border-radius: size(6);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        position: relative;
        @media screen and (min-width: $breakpoint-md) {
          height: size(100);
        }
        @media screen and (min-width: $breakpoint-lg) {
          height: size(120);
        }
        &.rs_paygo_standard {
          background: #ED90B2;
        }
        &.rs_paygo_premium {
          background: #97E6AB;
        }
        &.rs_paygo_enterprise_a101 {
          background: #A695FF;
        }
        img {
          position: absolute;
          display: inline-block;
          height: size(120);
          top: size(-40);
          @media screen and (min-width: $breakpoint-md) {
            height: size(120);
            top: size(-40);
          }
          @media screen and (min-width: $breakpoint-lg) {
            height: size(180);
            top: size(-60);
          }
        }
      }
      .content-block {
        padding: size(15);
        position: relative;
        min-height: size(320);
        & > h3 {
          font-size: size(15);
          line-height: size(21);
          color: var(--ca-title-color);
          font-weight: 700;
          padding-bottom: size(6);
        }
        p {
          font-size: size(12);
          line-height: size(17);
          padding-bottom: size(20);
        }
        .standard-price {
          font-size: size(15);
          line-height: size(21);
          font-weight: bold;
        }
        .button-wrapper {
          position: absolute;
          bottom: size(15);
          left: size(15);
          right: size(15);
          & > a {
            display: inline-block;
            color: var(--rz-link-color);
            font-size: size(12);
            line-height: size(17);
            font-weight: 500;
            margin-bottom: size(10);
            text-align: left;
            margin-top: size(20);
          }
          .error-field {
            height: size(30);
            .error-msg {
              padding: size(5) 0;
              margin: 0;
            }
            visibility: hidden;
            &.show-error {
              visibility: visible;
            }
          }
        }
        button {
          display: block;
          text-align: center;
          background-color: var(--rz-link-color);
          color: var(--rs-white-color);
          font-size: size(12);
          line-height: size(17);
          width: 100%;
          padding: size(10);
          border-radius: size(5);
          cursor: pointer;
        }
      }
      &.rs_paygo_standard {
        .content-block {
          .button-wrapper {
            & > a {
              margin-bottom: size(40);
            }
          }
        }
      }
    }
  }
}
.other-products {
  padding-bottom: size(20);
  a {
    font-size: size(12);
    line-height: size(17);
    font-weight: 500;
    color: var(--on-boarding-btn-bg);
    margin-left: size(50);
    &:hover {
      opacity: 0.8;
    }
    &:first-child {
      margin-left: 0;
    }
    img {
      display: inline-block;
      vertical-align: top;
      width: size(20);
      height: size(20);
      margin-right: size(8);
    }
  }
}
