@import "@/assets/styles/global/lib.scss";































.select-payment-wrapper {
  .variations-types-wrap {
    .variation {
      &.free {
        display: none;
      }
      &.prepay {
        border-bottom: none;
      }
    }
  }
}
