@import "@/assets/styles/global/lib.scss";














































































































































.forgot-password {
  .flex {
    display: block;
    font-family: var(--rz-hero-font);
  }
  .flex-col-right {
    max-width: size(380);
    margin: size(120) auto 0;
    padding: 0;
    .title {
      margin-bottom: size(30);
    }
    .button {
      margin-top: size(14);
    }
    .create-password-block {
      & >.error-msg {
        color: var(--onboard-error-color);
        padding: 0 0 size(10);
      }
      .checkbox-content {
        display: inline-block;
        vertical-align: middle;
        margin-top: size(20);
        padding-left: size(10);
        font-size: size(12);
        line-height: size(15);
        color: var(--ca-title-color);
      }
    }
    .success-field {
      text-align: center;
      &.email {
        p {
          padding-top: size(20);
          padding-bottom: 0;
        }
      }
      h1 {
        padding: size(10) 0;
        font-weight: bold;
        font-size: size(42);
        line-height: size(56);
        color: var(--ca-title-color);
        font-family: var(--hero-font);
      }
      p {
        padding-bottom: size(30);
        font-weight: 600;
        font-size: size(16);
        line-height: size(19);
        color: var(--ca-title-color);
      }
    }
  }
}

.flex {
  &-col {
    &-right {
      &.initiated {
          .tick-icon {
            background-image: url('../../assets/tick-icon.svg');
            background-repeat: no-repeat;
            background-position: 98% 50%;
            background-size: size(14) size(10);
          }
          .show {
            max-height: size(24);
          }
          .hide {
            max-height: 0;
          }
        }
    }
  }
}

.ants-product-forget-password {

  .form-group.error-field {
    .error-msg {
      color: var(--rz-ants-error-color);
    }
  }
  .ants-form-group {
    label {
      color: var(--rz-ants-color-text1);
    }
    input {
      background: var(--rz-ants-primary-bg);
      padding: size(12) size(25) size(12) size(16);
      margin-bottom: size(8);
      color: var(--rs-white-color);
      border: size(1) solid var(--rz-ants-color-border);

      &:hover {
        border-color: transparent;
      }

      &:focus {
        border-color: transparent;
      }
    }
  }
  .sign-up-text  {
    color: var(--rs-white-color);
  }
  .flex {
    background: var(--rz-ants-color-primary-bg);
  }
  .flex-col-right {
    background: transparent;
    font-family: var(--rz-ants-font);

    h1, .success-field p, .showSuccessBlock p,h1 {
      color: var(--rs-white-color);
    }

    .create-password-block .checkbox-content {
      color: var(--rz-ants-color-text1);
      text-align: center;
    }
  }
}
