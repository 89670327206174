@import "@/assets/styles/global/lib.scss";





































































































































































































.on-boarding-wrap {
  .skip-btn {
    margin-left: size(15);
    @media screen and (min-width: $breakpoint-md) {
      margin-left: size(30);
    }
  }
}
.promo-title {
  font-size: size(20);
  font-weight: 600;
  padding-top: size(20);
  display: inline-block;
}
.promo {
  .form-group {
    margin-bottom: 0;
  }
  .form-content {
    padding-top: size(5);
    .features {
      ul {
        padding-top: size(20);
        padding-left: size(20);
        padding-bottom: size(8);
        list-style-type: disc;
        li {
          padding-bottom: size(10);
          line-height: size(22);
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
      h4 {
        font-size: size(16);
        font-weight: 600;
        padding-top: size(20);
      }
    }
  }
}
