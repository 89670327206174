@import "@/assets/styles/global/lib.scss";
































.form-field-input {
  .amount-div{
    margin-bottom: size(36);

    &.error-field {
      .currency {
        top: 30%;
      }
    }
    .currency {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: size(10);
      display: inline-block;
    }
    input {
      border: size(1) solid #c4c4c4;
      border-radius: size(6);
      padding: size(10);
      padding-left: size(22);
    }
    &.error-field {
      input {
        border: size(1) solid #d83e3e;
      }
    }
  }
}
