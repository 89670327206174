@import "@/assets/styles/global/lib.scss";


























































.payment-method-section {
    /* custom radio field*/
    h4 {
      font-size: size(15);
      line-height: size(21);
      font-weight: 700;
      margin-bottom: size(20);
    }
    .payment-info {
      color: #333;
      margin-bottom: 0;
      margin-top: 8px;
      font-size: 14px;
      position: relative;
      // padding-left: 20px;
      position: relative;
      svg {
        position: absolute;
        width: 15px;
        height: 20px;
        top: -1px;
        left: 0;
      }
    }
    .payment-field-outer {
      padding: size(12) 0 0 size(32);
      margin: size(20) 0;
      border-top: size(1) solid #D8D8D8;;
      &:first-child {
        margin-top: 0;
        border-top: 0;
        padding-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .payment-field {
      display: block;
      position: relative;
      cursor: pointer;
      font-size: 17px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        &:checked {
          &~.checkmark:after {
            display: block;
          }
        }
      }
      &:hover {
        input ~ .checkmark {
          border: size(1) solid var(--rz-link-color);
        }
      }
      &.selected {
        color: var(--rz-link-color);
      }
      &.active {
        .type-heading {
          color: var(--rz-link-color);
          font-weight: 600;
        }
      }
      .payment-logo {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: none;
        @media screen and (min-width: $breakpoint-md) {
          display: block;
        }
        &.stripe {
          top: 30%;
          img {
            width: 42px;
          }
        }
        svg {
          height: size(18);
          width: size(13);
          &.razorpay.gateway, &.paypal {
            width: 100px;
          }
        }
        img{
          height: auto;
          width: size(100);
        }
      }
    }
    .checkmark {
      position: absolute;
      top: size(5);
      left: size(-30);
      height: size(15);
      width: size(15);
      border: size(1) solid var(--rz-link-color);
      border-radius: 50%;
      &:after {
        content: "";
        position: absolute;
        display: none;
        top: size(3);
        left: size(3);
        width: size(9);
        height: size(9);
        border-radius: 50%;
        background: var(--rz-link-color);
      }
    }
    .payment-field input:checked ~ .checkmark {
      border: size(1) solid var(--rz-link-color);
    }

    .type-heading {
      margin: 0 0 size(4);
      font-weight: 500;
      font-size: size(18);
      line-height: size(24);
      color: var(--rs-black-color);

    }
    .type-sub-heading {
      font-weight: normal;
      font-size: size(15);
      line-height: size(21);
      margin: 0;
      font-weight: normal;
      color: #585757;
      max-width: size(300);
    }
    .address-details {
      padding-left: size(32);
      margin-bottom: size(10);
    }
  }

  .ants-product-payment {
     h4, .type-heading {
      color: var(--rs-white-color);
     }
     .type-sub-heading {
      color: var(--rz-ants-color-text1);
     }

     .payment-field {

      input ~ .checkmark , input:checked ~ .checkmark{
        border-color: var(--rs-white-color);
      }

      input:checked ~ .checkmark {
        &::after {
          background: #F5FF78;
        }
      }

      &:hover {
        input ~ .checkmark {
          border-color: #F5FF78;
        }
      }
     }

     .payment-field-outer {
      border-color: var(--rz-ants-color-border);
     }

     .payment-field.active{
      .type-heading {
        color: var(--rs-white-color);
      }
     }
  }
