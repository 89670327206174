@import "@/assets/styles/global/lib.scss";

























































.payment-section {
  @media screen and (min-width: $breakpoint-md) {
    padding-top: size(55);
  }
  .step-label {
    font-size: size(12);
    line-height: size(17);
    font-weight: 500;
    color: var(--rs-black-color);
    margin-bottom: size(10)
  }
}
.disabled {
  cursor: not-allowed;
}
.billing-info {
  --gray-line: #c4c4c4;

  --billing-info-box-width: auto;

  @media screen and (min-width: $breakpoint-lg) {
    --billing-info-box-width: #{size(540)};
  }

  margin-top: size(20);
  font-size: var(--onboarding-description-font-size);
  line-height: var(--onboarding-description-line-height);
  width: var(--billing-info-box-width);

  .form-wrapper-section {
    padding: size(30);
    border-radius: size(8);
    box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
      0 size(2) size(1) rgba(0, 0, 0, 0.06),
      0 size(1) size(1) rgba(0, 0, 0, 0.08);
    background: var(--rs-white-color);
  }
  .terms-and-conditions {
    font-weight: 400;
    font-size: size(16);
    line-height: size(19);
    padding: size(20) 0 size(50);
    color: var(--rs-black-color);
    a {
      color: var(--rz-link-color);
    }
    svg {
      display: inline-block;
      vertical-align: middle;
      width: size(8);
      height: size(8);
      margin-left: size(5);
    }
  }

  h5 {
    font-weight: bold;
    color: var(--ca-title-color);
  }

  .page-request-state {
    .error-msg {
      display: block;
    }
  }
}

.ants-payment {
  >* {
    font-family: var(--rz-ants-font);
  }
  .prompt-box {
    display: flex;
    align-items: center;
    label {
      margin-bottom: 0;
    }
  }
  .step-label, h2 {
    color: var(--rs-white-color);
    font-family: var(--rz-ants-font);
  }
  .btn-wrap {
    .btn {
      background: #F5FF78;
      color: var(--rz-ants-color-text2);
      padding: size(12);
      border: none;
      font-weight: 500;
    }
    p {
      color: var(--rs-white-color);
    }
    a {
      color: #F5FF78;
      padding: 0;

      svg {
        fill: #F5FF78;
        stroke: #F5FF78;
      }
    }
  }
}
