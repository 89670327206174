@import "@/assets/styles/global/lib.scss";


























.user {
  &.user-links {
    a {
      display: block;
      margin: 2rem;
    }
  }
}
