@import "@/assets/styles/global/lib.scss";












































.console-onboard {
  --onboard-padding: #{size(22)};

  @media screen and (min-width: $breakpoint-lg) {
    --onboard-padding: #{size(52)};
  }
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  display: block;
  padding-right: var(--onboard-padding);
  padding-left: var(--onboard-padding);
  max-width: var(--content-max-width);
  margin: auto;
  &-wrapper {
    background-color: var(--onboard-bg-color);
  }
  .page-container {
    padding-bottom: size(65);
    &.form-style {
      padding: 0;
    }
  }
  &.form-style {
    padding: 0;
    max-width: 100%;
    width: 100%;
    background: #f5f5fa;
  }

  .header {
    padding: size(14) size(30);
    background: #1F27A3;
    a {
      display: inline-block;
      vertical-align: top;
      height: size(32);
      img {
        display: inline-block;
        vertical-align: top;
        width: size(32);
        height: size(32);
      }
      span {
        display: inline-block;
        vertical-align: top;
        margin-left: size(10);
        font-weight: bold;
        font-size: size(15);
        line-height: size(21);
        margin-top: size(6);
        color: var(--rs-white-color);
      }
    }
    .user-info {
      position: relative;
      float: right;
      & .user-dropdown {
        display: none;
      }
      &:hover .user-dropdown {
        display: block;
      }
      .user-name {
        display: inline-block;
        padding: size(6) size(12);
        border-radius: 50%;
        font-size: size(15);
        line-height: size(21);
        background: var(--rz-link-color);
        color: var(--rs-white-color);
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .footer {
    font-size: size(12);
    line-height: size(17);
    margin: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: calc(100% - #{size(104)});
    display: block;
    padding: 0;
    margin: auto;
    @media screen and (min-width: $breakpoint-md) {
      display: flex;
      justify-content: space-between;
      padding: size(11) var(--onboard-padding) size(11) var(--onboard-padding);
    }
    .flex-col-right {
      margin-top: size(15);
      @media screen and (min-width: $breakpoint-md) {
        margin-top: 0;
      }
      .link-item {
        margin-right: size(16);
        margin-bottom: size(8);
        @media screen and (min-width: $breakpoint-md) {
          margin-left: size(16);
          margin-bottom: 0;
          margin-right: 0;
        }
        color: var(--regular-text);
        display: inline-block;
      }
    }
  }

  svg {
    &.profile-icon {
      height: size(20);
      width: size(20);
    }
  }

  .hide {
    display: none;
  }
}

.ants-product {
  >* {
    font-family: var(--rz-ants-font);
  }
  .user-info {
    width: 100px;
    text-align: right;
  }
  .console-onboard {
    background: var(--rz-ants-color-primary-bg);
    .header {
      background: var(--rz-ants-primary-bg);
    }

    .page-container {
      .on-boarding-wrap {
        background: var(--rz-ants-color-primary-bg);

        .page-container {
          .split-cols {
            .column-2 {
              >* {
              font-family: var(--rz-ants-font);
              }
            }
          }
        }
        .billing-info__inner .disabled-country-msg {
          color: var(--rz-ants-text-disabled);
        }
        .form-group {
          .field-label {
            color: var(--rz-ants-color-text1);
            padding: 0;
            margin-bottom: size(8);
            font-size: size(14);
            font-weight: 400;
          }
          input, .dropdown select {
            background: var(--rz-ants-color-border);
            color: var(--rs-white-color);
            border: none;
            font-family: var(--rz-ants-font);
            padding: size(12) size(25) size(12) size(16);
            border-radius: size(4);
            margin-bottom: size(8);
            font-size:  size(14);
            line-height: size(20);
          }
          input:-webkit-autofill {
            -webkit-text-fill-color: var(--rs-white-color);
            background: transparent;
            -webkit-tap-highlight-color: transparent;
            -webkit-box-shadow: 0 0 0 size(30) var(--rz-ants-color-border) inset;
          }
          input:-webkit-autofill:hover, input:-webkit-autofill:focus {
            background: var(--rz-ants-color-border);
          }
          .dropdown.disabled {
            opacity: 0.6;
          }
          .dropdown {
            select {
            background-image: url(../../assets/ants_arrow_down.svg);
            background-repeat: no-repeat;
            background-position: calc(100% - 6px) size(19);
            }
          }
        }

          .billing-info  {
          .form-group {
            .form-field-input {
              .error-msg {
                margin-top: size(-5);
              }
            }
          }
          .terms-and-conditions {
              a {
                color: var(--rz-ants-primary-button-bg);
                background: transparent;
                padding: 0;
              }
            }
            .form-wrapper-section {
              background: var(--rz-ants-primary-bg);
              border-radius: size(20);
              padding: size(20);
              border: 1px solid var(--rz-ants-color-border);

              h5 {
              color: var(--rs-white-color);
              }
              label {
                color: var(--rz-ants-color-text1);
                margin-bottom: size(8);
                font-size: size(14);
              }
              .prompt-box, .save-card {
                label {
                  margin-bottom: 0;
                }
                input {
                  margin-bottom: 0;
                }
              }
            }

            .page-request-state {
              .loading-msg {
                color: var(--rs-white-color);
              }
            }
          }

        .btn-wrap {
          a, .btn {
            background: var(--rz-ants-primary-button-bg);
            color: var(--rz-ants-color-text2);
            padding: size(12);
            border-radius: size(4);
            font-weight: 500;
            border: none;
          }
          .btn {
            &:hover {
              animation: none;
              color: var(--rz-ants-color-text2);
            }
          }
        }

        .success-notifier {
          h2 {
            color: var(--rs-white-color);
            font-family: var(--rz-ants-font);
          }
          p {
            color: var(--rz-ants-color-text1);
            font-family: var(--rz-ants-font);
          }
        }

        .error-field {
          input {
            border: 1px solid var(--rz-ants-error-color) ;
          }
          .error-msg {
            color: var(--rz-ants-error-color);
            margin-top: size(5);
          }
        }

      }

    }
    .footer {
      .flex-col-left {
        p {
          color: var(--rs-white-color);
        }
      }
      .flex-col-right {
        .link-item {
          a {
            color: var(--rz-ants-color-text1);
            &:hover {
              color: var(--rz-ants-primary-button-bg);
            }
          }
        }
      }
    }
  }
}
