@import "@/assets/styles/global/lib.scss";








































































































































































































































































.steps-wrapper {
  padding-left: 0;
  h4 {
    font-size: size(12);
    line-height: size(17);
    font-weight: 500;
    color: var(--rs-black-color);
    margin-bottom: size(10)
  }
  .change-button {
    visibility: hidden;
  }
}
.ants-project-create {
  font-family: var(--rz-ants-font);
  h2, h4 {
    color: var(--rs-white-color);
   }
}
