@import "@/assets/styles/global/lib.scss";



































.stripe-card-box {
  border: 1px solid #c2c2c2;
  margin-top: size(16);
  padding: size(12);
  border-radius: size(5);

  &__focus {
    border-color: var(--onboard-link-color);
  }
}
.ants-card-box {
  border-color: var(--rz-ants-color-border);
  margin-bottom: size(8);
  padding: size(12) size(25) size(12) size(16);
  border-radius: size(4);
  background: var(--rz-ants-color-border);

  &__focus {
    border-color: transparent;
  }
}
