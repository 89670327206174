@import "@/assets/styles/global/lib.scss";





































































.save-card {
  font-size: size(14);
  line-height: size(20);
  display: flex;
  gap: size(10);
  padding-top: size(10);
  color: var(--rs-black-color);
  font-weight: 500;
}

.invoice-logo {
  max-width: size(50);
  max-height: size(25);
}
.paypal-logo {
  max-width: size(60);
}
