@import "@/assets/styles/global/lib.scss";


































































































































.choose-project {
  max-width: size(540);
  margin-bottom: size(80);
  h2 {
    max-width: 100%;
    padding-right: 0;
  }
  h4 {
    font-size: size(12);
    line-height: size(17);
    font-weight: 500;
    color: var(--rs-black-color);
    margin-bottom: size(10)
  }
  .title-block {
    padding-bottom: size(14);
    text-align: center;
    hr {
      width: size(30);
      border: size(1) solid #DADADA;
      display: inline-block;
      vertical-align: bottom;
      @media screen and (min-width: $breakpoint-lg) {
        width: size(140);
      }
    }
    span {
      font-size: size(15);
      line-height: size(21);
      display: inline-block;
      color: var(--rs-black-color);
      padding: 0 size(10);
    }
  }
  .btn-wrap {
    margin-top: size(22);
  }
  .projects {
    padding: size(20);
    background: var(--rs-white-color);
    margin: 0 auto;
    border-radius: size(8);
    box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
      0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
    .list-wrapper {
      display: inline-block;
      width: 94%;
      @media screen and (min-width: $breakpoint-md) {
        width: 96%;
      }
    }
    svg {
      display: inline-block;
      vertical-align: top;
      fill: var(--rs-white-color);
      stroke: var(--rz-link-color);
      width: size(12);
      height: size(12);
      margin-top: size(18);
    }
    .link-img {
      display: inline-block;
      vertical-align: top;
      margin-top: size(16);
    }
    a {
      &:hover {
        color: var(--regular-text);
      }
    }
    .projects-item {
      width: 100%;
      border-bottom: size(1) solid #DADADA;
      cursor: pointer;
      display: block;
      padding: size(15) 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
      .projects-item-title {
        font-weight: 700;
        font-size: size(15);
        line-height: size(21);
        color: var(--rz-link-color);
        padding-bottom: size(6);
        display: block;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .projects-item-license {
        font-size: size(14);
        line-height: size(17);
        font-weight: 400;
        text-align: left;
        display: block;
        color: var(--rs-black-color);
      }
    }
  }
  .btn-wrap {
    .btn {
      &.skip-btn {
        font-size: size(15);
        line-height: size(17);
        font-weight: 400;
        padding: size(14) 0;
        border-radius: size(5);
        width: 100%;
        background: transparent;
        border: size(1) solid var(--rs-black-color);
        color: var(--rs-black-color);
        margin-left: 0;
        margin-bottom: size(28);
      }
    }
  }
}

.ants-product-choose-project {
  >* {
    font-family: var(--rz-ants-font)
  }

  .back {
    visibility: hidden;
  }

  h4, h2 {
  color: var(--rs-white-color);
  }

  .change-button {
    p {
      color: #F5FF78;
    }
  }

  .btn-wrap {
    .btn {
      &.skip-btn {
        background-color: #F5FF78;
        border: none;
        text-align: center;
      }

      &:hover {
        animation: none;
      }
    }
  }

  .title-block {
    hr {
      border-color: var(--rz-ants-color-border);
    }
    span {
      color: var(--rs-white-color);
    }
  }

  .projects {
    background-color: var(--rz-ants-primary-bg);
    .projects-item {
      border-color: var(--rz-ants-color-border);
      .projects-item-title {
        color: var(--rs-white-color);
      }
      .projects-item-license {
        color: var(--rz-ants-color-text1);
      }

      .link-img-arrow {
        color: var(--rs-white-color);
        stroke: none;
      }
    }
  }
}
