@import "@/assets/styles/global/lib.scss";












































.page-container {
  max-width: 100%;
  margin: auto;
  padding-bottom: size(50);
  @media screen and (min-width: $breakpoint-md) {
    max-width: var(--content-max-width);
  }
}
.docs-wrap {
  padding-top: var(--master-menu-height);
  margin-top: 0
}
