@import "@/assets/styles/global/lib.scss";

























































































































































































































































































































































.progress-bar__container {
  width: size(325);
  font-family: var(--rz-hero-font);
  border-radius: size(8);
  margin-bottom: size(100);

  @media screen and (min-width: $breakpoint-md) {
    margin-top: size(135);
  }

  .coupon-error {
    color: var(--onboard-error-color);
    font-weight: 500;
    padding-bottom: size(10);
    text-align: center;
    transition: opacity 0.8s linear;
    animation: blinker 1.5s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  .image-wrapper {
    border-top-left-radius: size(8);
    border-top-right-radius: size(8);
    background: #93E8EB;
    height: size(80);
    &.paygo {
      background: linear-gradient(0deg, #97E6AB, #97E6AB), #79CEA5;
    }
    &.rs_package_icc_wc_t20_2022_6f3,  &.rs_package_icc_wc_t20_2022_177 {
      background: #A9C6FC;
    }
    &.rs_package_iplt20_2022_689 {
      background: #93E8EB;
    }
    &.rs_package_c__board__icc__c2ab7ee61_1ad, &.rs_package_c__board__icc__c2ab7ee61_72a {
      background: #6FCAF2;
    }
    img {
      width: size(200);
      &.package-img {
        width: size(100);
      }
    }
  }

  .popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999;
    .popup {
      position: fixed;
      z-index: 1000;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      border-radius: size(2);
      width: size(300);
      height: size(450);
      overflow: scroll;
      @media screen and (min-width: $breakpoint-md) {
        width: size(680);
        height: auto;
        overflow: auto;
      }
      .popup-header {
        svg {
          position: absolute;
          right: size(20);
          top: size(20);
          width: size(10);
          height: size(10);
          cursor: pointer;
        }
      }
    }
  }

  h2 {
    text-align: center;
    background-color: var(--progress-bar--pointer--Color);
    padding: size(12) 0;
    font-size: size(20);
    line-height: size(22);
    color: var(--master-text);
    font-weight: bold;
    border-top-left-radius: size(6);
    border-top-right-radius: size(6);
    margin-bottom: 0;
    font-family: var(--regular-font);
  }
}
.progress-bar__content {
  position: relative;
  box-shadow: 0 size(1) size(3) rgba(51, 51, 51, 0.1),
    0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
  border-radius: size(8);
}
.additional-info {
  padding: size(15) 0;
  margin: 0 size(20);
  border-top: size(1) dashed #C4C4C4;
  svg {
    display: inline-block;
    width: size(14);
    height: size(14);
    margin-right: size(14);
  }
  p {
    display: inline-block;
    vertical-align: top;
    width: 88%;
    font-weight: 400;
    font-size: size(12);
    line-height: size(17);
    color: var(--rs-black-color);
  }
  a {
    text-decoration: underline;
    &:hover {
      color: var(--rs-black-color);
    }
  }
}
.description {
  p {
    padding-top: size(12);
    font-size: size(14);
    line-height: size(19);
    color: var(--rs-black-color);
    &:first-child {
      padding-top: size(20);
    }
  }
  sup {
    font-weight: 700;
  }
}
sup {
  color: var(--rz-link-color);
  vertical-align: super;
  font-size: size(10);
  font-weight: 700;
}
.progress-bar__wrapper {
  background: var(--rs-white-color);
  border-bottom-left-radius: size(8);
  border-bottom-right-radius: size(8);
  .progress-bar {
    .text-container {
      display: inline-block;
      vertical-align: top;
      height: size(40);
      width: size(220);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      h3 {
        font-size: size(15);
        line-height: size(22);
        font-weight: bold;
        padding-bottom: size(4);
      }
      span {
        font-size: size(14);
        line-height: size(17);
      }
    }
  }
  .form-group {
    &.custom-radio {
      margin-bottom: size(16);
      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        margin-top: size(16);
      }
    }
  }
  .plan-details-container {
    padding: size(20);
    .label {
      font-weight: 500;
      font-size: size(12);
      line-height: size(17);
      color: #777777;
      padding-bottom: size(4);
    }
    .project-name {
      padding-top: size(15);
      &.remove-padding {
        padding-top: 0;
      }
    }

    .plan-validity {
      padding-top: size(15);

      p {
        font-weight: 500;
        font-size: size(15);
        line-height: size(22);
      }

      .sub-heading {
        color: #585757;
        font-weight: normal;
        font-size: size(15);
        line-height: size(19);
        font-weight: normal;
        padding-top: size(6);
      }
    }
    .project-name, .plan-details {
      p, .plan-name {
        font-weight: 500;
        font-size: size(15);
        line-height: size(22);
        color: var(--rs-black-color);
        max-width: size(135);
        word-break: break-word;
        &.price {
          margin-right: 0;
          max-width: initial;
        }
        &.saved-price {
          font-size: size(12);
          line-height: size(17);
          color: #00A656;
          max-width: initial;
          padding-top: size(5);
        }
      }
      a, .view-details-btn {
        display: block;
        cursor: pointer;
        margin-top: size(6);
        font-size: size(12);
        line-height: size(17);
        font-weight: 500;
        width: size(105);
        color: var(--rz-link-color);
      }
    }
    .plan-name {
      span {
        width: 100%;
      }
    }
    .plan-details {
      div {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        &:nth-child(2), &:nth-child(3) {
          text-align: right;
        }
      }
      .dropdown-wrapper {
        display: block;
        text-align: right;
        width: 100%;
        .dropdown {
          width: size(98);
          font-size: size(12);
          font-weight: 500;
          line-height: size(17);
          border: size(1) solid #C4C4C4;
          border-radius: size(8);
          padding: size(8) size(12);
          margin-top: size(6);
          margin-bottom: size(12);
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          cursor: pointer;
          background: url('../../assets/dropdown.svg') no-repeat;
          background-size: size(8) size(4);
          background-position: right size(14) bottom size(14);
          background-color: var(--rs-white-color);
          option {
            font-size: size(12);
            line-height: size(17);
          }
        }
      }
      &.hide {
        display: none;
      }
    }
    .billing-cycle-container {
      .label {
        display: block;
        padding-bottom: size(8);
        margin-top: size(16);
      }
      .cart-choices {
        display: flex;
        align-items: start;
        justify-content: space-between;
        padding: size(16);
        border-radius: size(8);
        border: size(1) dashed var(--rs-border-gray-color);

        transition: border 0.5s linear;

        &.active {
          border: size(1) solid #00A656;
        }

        .checkbox-container {
          .checkmark  {
            top: size(20);
            left: size(16);
            border-color: var(--rs-border-gray-color);

            &::after {
              background: #00A656;
            }
          }

          input {
            left: 0;
            top: 0;
            height: size(74);
          }
        }
        .price-section {
          text-align: right;
          .saved-price {
            font-size: size(12);
            line-height: size(17);
            font-weight: 500;
            color: #00A656;
            max-width: initial;
            margin-top: size(2);
          }

          .price {
            font-weight: 700;
            font-size: size(15);
            line-height: size(21);
            color: var(--rs-black-color);
            margin-top: 0;
          }
        }

        .billing-cycle {
          margin-left: size(24);
          p {
            font-size: size(12);
            line-height: size(17);
            color: #777777;
            padding-bottom: size(4);
            margin-top: 0;
          }
          span {
            font-weight: 500;
            font-size: size(15);
            line-height: size(21);
            color: var(--rs-black-color);
          }
        }
      }
    }
  }
  .due-date__container {
    margin: 0 size(20) 0;
    padding: size(15) 0;
    border-top: size(1) dashed #C4C4C4;;
    p {
      display: flex;
      align-content: space-between;
      margin-bottom: size(16);
      &:last-child {
        margin-bottom: 0;
      }
    }
    div{
      &:first-child {
        padding-bottom: size(16);
      }
    }
    span {
      font-size: size(15);
      line-height: size(19);
      vertical-align: sub;
      &.due-amount {
        flex-basis: 35%;
        text-align: right;
        align-self: flex-end;
        font-weight: 600;
      }
      &.due-date {
        flex: 1;
        text-align: left;
      }
    }
  }
  .payment-details__container {
    h3 {
      font-size: size(15);
      line-height: size(22);
      font-weight: 600;
    }
    .align-right {
      float: right;
    }
    .promo-code {
      padding: size(11) size(25);
      background: var(--progress-bar--promo--Color);
      font-size: size(16);
      line-height: size(19);
      font-weight: bold;
    }
    .upfront-amount {
      padding: size(12) 0;
      font-size: size(15);
      line-height: size(22);
      span {
        display: inline-block;
        &:first-child {
          max-width: size(160);
        }
      }
      .align-right {
        font-size: size(15);
        line-height: size(22);
      }
    }
  }
  .total-bill__container {
    margin: 0 size(20) size(20);
    padding: 0 0 size(20);
    font-size: size(15);
    line-height: size(22);
    .discount-lines,.tax-lines {
      padding-bottom: size(10)
    }
    .tax-lines {
      &.add-border {
        padding-top: size(15);
        border-top: size(1) dashed #C4C4C4;
        &:empty {
          padding-top: 0;
          border-top: none;
        }
      }
      p {
        &:last-child {
          padding-top: size(15);
        }
        &.tax-line-0 {
          padding-top: 0;
        }
      }
    }
    .payment-details__container {
      padding-top: size(15);
      border-top: size(1) dashed #C4C4C4;
    }
    .total {
      margin-top: size(3);
      border-top: size(1) dashed #C4C4C4;
      padding: size(20) 0 0;
      span {
        font-size: size(18);
        line-height: size(22);
        &.align-right {
          font-weight: 700;
        }
      }
    }
    .align-right {
      color: var(--progress-bar--pointer--Color);
      float: right;
    }
    .discount {
      padding: size(15) 0 0;
      font-size: size(16);
      line-height: size(19);
      font-weight: normal;
      .align-left {
        display: inline-block;
        max-width: size(170);
      }
      .align-right {
        font-size: size(16);
        line-height: size(22);
        color: var(--ca-title-color);
        display: inline-block;
        vertical-align: baseline;
      }
    }
  }
  .animate-loading-state {
      transition: all 600ms cubic-bezier(.95,.05,.8,.04);
      // transition-delay: 100ms;
  }

  .loading-state-placeholder {
    text-align: center;
    font-size: size(12);
    line-height: 1.2;
    text-transform: uppercase;
    .msg {
      display: none;
      &.active {
        display: block;
      }
    }
  }

  .cart-preview {
    &.updating {
      .cart-preview-container {
        opacity: 0.5;
        transform: scale(0.95);
      }
    }
  }
}
.ants-product-progress-bar {
  .image-wrapper {
    background: #303943;
  }

  .additional-info {
    color: var(--rs-white-color);
  }

  .loading-state-placeholder {
    background: var(--rz-ants-primary-bg);
    color: var(--rs-white-color);
  }

  .progress-bar__wrapper {
    background: var(--rz-ants-primary-bg);
    .plan-details-container, .cart-preview {
      background: var(--rz-ants-primary-bg);
    }

    .cart-preview {
      .cart-preview-container {
        .total-bill__container {
          .tax-lines , .total, .discount-lines {
            color: var(--rs-white-color);
            border-color: var(--rz-ants-color-border);
          }
        }
      }
    }
    .plan-details-container {
      .plan-details {
        .view-details-btn {
          color: #F5FF78;
          display: none;
        }
        span {
          color: var(--rz-ants-color-text1);
        }
        p, .plan-name  {
          color: var(--rs-white-color);
        }
      }
      .project-name {
        span {
          color: var(--rz-ants-color-text1);
        }

        p {
          color: var(--rs-white-color);
        }
      }
    }
  }
}

