@import "@/assets/styles/global/lib.scss";































































































































































































































































































































.image-area {
  .show-container {
    img {
      max-height: size(435);
    }
  }
}
.sign-up {
  .flex-col-right {
    .form-wrapper {
      padding-bottom: size(100);
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(180);
      }
    }
  }
}

.flex {
  &-col {
    &-right {
      &.initiated {
          .tick-icon {
            background-image: url('../../assets/tick-icon.svg');
            background-repeat: no-repeat;
            background-position: 98% 50%;
            background-size: size(14) size(10);
          }
          .show {
            max-height: size(24);
          }
          .hide {
            max-height: 0;
          }
        }
    }
  }
}

.ants-product-login  {
   .flex-col-right  {
    font-family: var(--rz-ants-font);
    background: var(--rz-ants-color-primary-bg);
    color: var(--rs-white-color);

    .form-wrapper {
       .signup-text {
         color: var(--rs-white-color);
       }

       a {
        color: var(--rz-ants-primary-button-bg);
       }

       .form-group {
        label {
          color: var(--rz-ants-color-text1);
        }
        input {
          background: var(--rz-ants-primary-bg);
          padding: size(12) size(25) size(12) size(16);
          margin-bottom: size(8);
          color: var(--rs-white-color);
          border-color: transparent;
          outline: none;

          &:hover {
            border-color: transparent;
          }
          &:focus {
            border: size(1) solid var(--rz-ants-color-border);
            outline: none;
          }
        }
        input:-webkit-autofill {
          -webkit-text-fill-color: var(--rs-white-color);
           background: transparent;
           -webkit-tap-highlight-color: transparent;
           -webkit-box-shadow: 0 0 0 size(30) var(--rz-ants-color-border) inset;
        }
        input:-webkit-autofill:hover, input:-webkit-autofill:focus {
           background: var(--rz-ants-color-border);
        }
      }
    }
   }
}
