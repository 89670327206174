@import "@/assets/styles/global/lib.scss";
.on-boarding-wrap {
  // Include local variables
  --on-boarding-primary-backgroundColor: #F5F5FA;
  --on-boarding-primary-solid-backgroundColor: #E9E8FC;
  --on-boarding-btn-bg: #0055BF;
  --on-boarding-suggestion-text: #F37A03;
  --on-boarding-border-color: #c4c4c4;
  --on-boarding-btn-width: auto;
  --on-boarding-btn-width-reduced: auto;
  --on-boarding-variation-type-width: auto;
  --on-boarding-dropdown-width: auto;
  --on-boarding-input-max-width: #{size(420)};
  --content-max-width: #{size(980)};
  --progress-bar-column-width: #{size(250)};
  --onboarding-title-font-size: #{size(24)};
  --onboarding-title-line-height: #{size(32)};
  --onboarding-description-font-size: #{size(20)};
  --onboarding-description-line-height: #{size(24)};
  --onboarding-subcontent-font-size: #{size(15)};
  --onboarding-subcontent-line-height: #{size(20)};
  --btn-pad-top: #{size(18)};

  
  @media screen and (min-width: $breakpoint-lg) {
    --on-boarding-btn-width: #{size(380)};
    --on-boarding-btn-width-reduced: #{size(250)};
    --onboarding-title-font-size: #{size(24)};
    --onboarding-title-line-height: #{size(32)};
    --on-boarding-variation-type-width: #{size(500)};
    --on-boarding-dropdown-width: #{size(515)};
    --progress-bar-column-width: #{size(325)};
  }

  background-color: var(--on-boarding-primary-backgroundColor);
  font-family: var(--rz-hero-font);
  &.docs-wrap {
    padding-top: size(65);
  }
  
  .change-button {
    cursor: pointer;
    svg {
      display: inline-block;
      vertical-align: top;
      width: size(12);
      height: size(17);
      margin-right: size(10);
    } 
    p {
      display: inline-block;
      vertical-align: top;
      font-size: size(12);
      line-height: size(17);
      color: var(--rz-link-color);
      margin-bottom: size(40);
    }
  }

  .billing-info {
    &__inner {
      .business-form-group {
        margin: size(20) 0;
        .form-group {
          margin-top: 0;
          margin-bottom: size(20);
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .form-group {
        margin: size(0) 0 size(20);
        // min-height: size(50);
        .dropdown {
          width: 100%;
        }
        &.two-column {
          display: flex;
          align-items: center;
          >div {
            position: relative;
            flex-basis: 50%;
            &:last-child {
              margin-left: size(12);
            }
          }
          .error-field {
            .error-msg {
              position: absolute;
            }
          }
        }
      }
      .form-group {
        input, select {
          max-width: size(1000);
        }
        .dropdown {
          max-width: size(1000);
        }
      }
      .prompt-box {
        min-height: 0;
        margin-bottom: 0;
        input {
          display: inline-block;
          width: size(20);
          height: size(20);
          vertical-align: middle;
        }
        label {
          vertical-align: middle;
          font-size: size(15);
          line-height: size(17);
          font-weight: 500;
          color: var(--regular-text);
          padding-left: size(10);
        }
      }
      .name-box {
        margin-top: size(20);
      }
  
      .disabled-country-msg {
        font-size: size(12);
        font-style: italic;
        color: gray;
        padding-bottom: size(12);
      }
    }
  }

  .split-cols {
    display: block;
  }
  .progress-bar {
    width: var(--progress-bar-column-width);
    
    display: none;
    @media screen and (min-width: $breakpoint-md) {
      display: inherit;
    }
    @media screen and (min-width: $breakpoint-lg) {
      margin-right: size(80);
    }
  }
  h2 {
    font-family: var(--rz-hero-font);
    font-weight: bold;
    font-size: var(--onboarding-title-font-size);
    line-height: var(--onboarding-title-line-height);
    color: var(--ca-title-color);
    margin-bottom: size(8);
  }
  .description {
    color: var(--ca-title-color);
    font-size: var(--onboarding-description-font-size);
    line-height: var(--onboarding-description-line-height);
  }
  .error-msg {
    display: none;
  }
  .error-field {
    .error-msg {
      svg {
        width: 16px;
        height: 16px;
        vertical-align: middle;
      }
      display: block;
      color: var(--onboard-error-color);
      margin-top: size(5);
      font-size: size(12);
    }
    .variations-types-wrap {
      border-color: var(--onboard-error-color);
    }
  }
  // Floating Placeholder Styles
  .form-group {
    position: relative;
    margin-bottom: size(4);
    p {
      margin-top: size(10);
    }
    &.error-field {
      input {
        outline: none;
        box-shadow: none;
        border-color: var(--onboard-error-color);
      }
    }
    .error-field {
      input {
        outline: none;
        box-shadow: none;
        border-color: var(--onboard-error-color);
      }
    }
    input, select {
      padding: size(10);
      width: 100%;
      max-width: var(--on-boarding-input-max-width);
      display: block;
      box-sizing: border-box;
      font-size: size(15);
      line-height: size(21);
      border-radius: size(3);
      border: size(1) solid var(--on-boarding-border-color);
      color: var(--ca-title-color);
      font-family: var(--rz-hero-font);
      padding-right: size(25);
      background-color: var(--rs-white-color);
      &:focus {
        outline: none;
        border: size(1) solid var(--rz-link-color);
      }
    }
    select {
      cursor: pointer;
      option {
        font-size: size(12);
        line-height: size(17);
      }
    }
    .field-label {
      display: inline-block;
      padding-bottom: size(6);
      color: #777777;;
      font-size: size(12);
      line-height: size(17);
      font-weight: 500;
      &:empty {
        padding: 0;
      }
    }
  }
  .billing-info {
    .form-group {
      .dropdown {
        select {
          max-width: size(1000);
        }
      }
    }
  }
  // Custom Radio
  .custom-radio {
    .checkmark {
      position: absolute;
      top: 5px;
      left: size(-24);
      height: size(14);
      width: size(14);
      border: size(1) solid var(--on-boarding-btn-bg);
      border-radius: 50%;
      &:after {
        content: "";
        position: absolute;
        display: none;
        top: size(2);
        left: size(2);
        width: size(10);
        height: size(10);
        border-radius: 50%;
        background: var(--on-boarding-btn-bg);
      }
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      left: size(-25);
      height: size(50);
      z-index: 9;
      &:checked {
        &~.checkmark:after {
          display: block;
        }
      }
      &.enterprise {
        height: size(70);
      }
    }
    &:hover {
      input ~ .checkmark {
        background-color: transparent;
        border: size(1) solid var(--on-boarding-btn-bg);
      }
    }
    &.selected {
      color: var(--on-boarding-btn-bg);
    }
    &.active {
      .type-heading {
        color: var(--on-boarding-btn-bg);
        font-weight: 600;
      }
    }
  }
  .form-body {
    margin-top: size(75);
  }
  .btn-wrap {
    margin-top: size(40);
    p {
      a {
        margin-top: size(40);
      }
    }
    .btn {
      background-color: var(--rz-link-color);
      color: var(--regular-text-inv);
      font-weight: 700;
      padding: size(15);
      font-size: size(15);
      line-height: size(17);
      text-transform: initial;
      letter-spacing: initial;
      width: var(--on-boarding-btn-width-reduced);
      &.link {
        border: none;
      }
      &.focus {
        border-color: var(--rz-link-color);
      }
    }
  }
  .column-1 {
    position: relative;
    flex: 1;
  }
  .back-btn {
    position: absolute;
    top: size(15);
    cursor: pointer;
  }
  .steps-wrapper {
    padding-left: size(0);
    padding-bottom: size(60);
    .new-project {
      h2 {
        margin-bottom: 0;
      }
      .form-body {
        margin-top: size(4);
      }
    }
  }
  .form-body {
    width: var(--on-boarding-btn-width);
    .form-group {
      margin-bottom: size(30);
    }
  }
  .select-license {
    >.form-group {
      margin-top: size(40);
    }
    .form-group {
      select {
        border: size(1) solid var(--onboard-link-color);
      }
    }
    h3 {
      .suggestion {
        color: var(--on-boarding-suggestion-text);
        font-weight: normal;
        font-size: var(--onboarding-subcontent-font-size);
        line-height: var(--onboarding-subcontent-line-height);
        text-transform: capitalize;
      }
    }
    .other-products {
      padding-bottom: size(20);
      a {
        font-family: var(--regular-font);
        font-size: var(--onboarding-description-font-size);
        line-height: var(--onboarding-description-line-height);
        color: var(--on-boarding-btn-bg);
        margin-left: size(50);
        &:hover {
          opacity: 0.8;
        }
      }
      .compare-btn{
        outline: none;
        border: none;
        cursor: pointer;
        font-family: var(--regular-font);
        font-size: var(--onboarding-description-font-size);
        line-height: var(--onboarding-description-line-height);
        color: var(--on-boarding-btn-bg);
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .user-rights-block {
      margin-top: size(22);
      h4 {
        font-size: size(16);
        line-height: size(19);
        font-weight: 600;
        margin-bottom: size(10);
      }
      ul {
        list-style: none;
        padding-left: size(17);
      }
      li {
        font-size: size(16);
        line-height: size(19);
        margin-bottom: size(10);
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          content: "\2022";
          color: var(--on-boarding-btn-bg);
          font-weight: bold;
          display: inline-block; 
          width: 1em;
          margin-left: -1em;
        }
      }
    }
    .variations-types-wrap {
      margin-bottom: size(24);
      .variation {
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }
        p {
          font-size: size(15);
          line-height: size(21);
          color: var(--rs-black-color);
          strong {
            color: var(--ca-title-color);
            font-weight: 600;
          }
        }
      }
    }
    .error-msg {
      margin-top: size(-10);
      margin-bottom: size(10);
    }
  }
  .variations-types-wrap {
    border: size(1) solid #ededed63;
    border-radius: size(6);
    margin-top: size(28);
    padding: size(16) size(24);
    width: var(--on-boarding-variation-type-width);
    margin-bottom: size(20);
    background: var(--rs-white-color);
    box-shadow: 0 size(1) size(3) rgba(51, 51, 51, 0.1),
      0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
    .variation {
      position: relative;
      padding-left: size(24);
      border-bottom: size(1) solid var(--gray-line);
      padding-top: size(12);
      padding-bottom: size(12);
      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
      &.rs_paygo_enterprise_a101 {
        border-bottom: 0;
        padding-bottom: size(12);
      }
      &:first-child {
        padding-top: 0;
      }
      h3 {
        font-weight: bold;
        font-family: var(--regular-font);
        font-size: var(--onboarding-description-font-size);
        line-height: var(--onboarding-description-line-height);
      }
      strong {
        color: var(--ca-title-color);
        font-weight: 600;
      }
      &:first-child {
        p {
          padding-top: size(5);
        }
      }
      p {
        padding-top: size(2);
        line-height: size(21);
      }
    }
  }
  .dropdown {
    .dropdown__list__wrap,
    .dropdown__list {
      background-color: var(--on-boarding-primary-backgroundColor);
    }
    .dropdown__list__default {
      padding-left: 0;
    }
    .dropdown__list {
      padding-left: size(5);
    }
    .dropdown__list__default {
      border: size(1) solid var(--on-boarding-border-color);
      color: var(--ca-title-color);
      border-radius: size(3);
      padding: size(10);
      input {
        &:focus {
          outline: none;
          border: none;
        }
      }
    }
    &.error-field {
      .dropdown__list__default {
        border-color: var(--onboard-error-color);
      }
    }
  }
  .terms-conditions {
    margin-bottom: size(20);
    p{
      color: #000;
    }
    a {
      font-family: var(--regular-font);
      font-size: size(16);
      line-height: size(20);
      color: var(--on-boarding-btn-bg);
      &:hover {
        opacity: 0.8;
      }
    }
    img {
      height: size(10);
      width: size(10);
    }
    span.license-name {
      font-weight: 600;
    }
  }
  .skip-btn {
    cursor: pointer;
    color: var(--on-boarding-btn-bg);
    margin-left: size(30);
    font-weight: 600;
    &:hover {
      opacity: 0.8;
    }
  }
  .quote {
    font-weight: 600;
    font-size: size(24);
    line-height: size(29);
    margin: size(10) 0;
  }
  .success-notifier {
    text-align: center;
    max-width: size(600);
    margin: 0 auto;
    &.virtual-sports {
      max-width: size(700);
    }
    h2 {
      padding-right: 0;
    }
    ~.btn-wrap {
      text-align: center;
    }
    p {
      font-size: size(16);
      line-height: size(20);
    }
    svg {
      padding-bottom: size(50);
    }
  }
  .textarea {
    width: var(--on-boarding-btn-width);
    padding: size(12);
    font-size: size(20);
    line-height: size(24);
    border: size(1) solid var(--gray-line);
    color: var(--ca-title-color);
    font-family: var(--regular-font);
    box-sizing: border-box;
    border-radius: size(6);
    resize: none;
    margin-top: size(35);
    &:focus {
      outline: 0;
    }
  }
  
  @media screen and (min-width: $breakpoint-md) {
    .split-cols {
      display: flex;
      justify-content: space-between;
      gap: size(30);
      .progress-bar {
        display: inherit;
      }  
    }
    .steps-wrapper {
      padding-left: 0;
    }
  }
}