@import "@/assets/styles/global/lib.scss";








































.on-boarding-wrap {
  padding-top: size(65);
  margin-top: 0;
  margin: 0 size(30);
  @media screen and (min-width: $breakpoint-lg) {
    margin: 0 0 auto;
  }
  .page-container {
    max-width: var(--content-max-width);
    margin: auto;
  }

  .page-request-state {
    .error-msg {
      display: block;
    }
  }
}
