@import "@/assets/styles/global/lib.scss";

















































































































































































































































































































































.form-wrapper-section {
  padding: size(30);
  border-radius: size(8);
  box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
    0 size(2) size(1) rgba(0, 0, 0, 0.06),
    0 size(1) size(1) rgba(0, 0, 0, 0.08);
  background: var(--rs-white-color);
  margin-bottom: size(20);
}
