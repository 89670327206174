@import "@/assets/styles/global/lib.scss";













































































































































































































































































































.form-group {
  display: flex;
  flex-flow: column;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}
